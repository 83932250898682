import React, { useEffect, useReducer, useMemo } from 'react';
import { ConfigProvider } from '@didi/ec-base';
import { utils, newRequest } from '@didi/pagelets-sdk';
import PageHeader from './components/PageHeader'; // 页面头部
import PageContent from './components/PageContent'; // 页面内容
import PageBottom from './components/PageBottom'; // 页面底部
import classNames from 'classnames';
import { PublicStore, getApolloConfig } from './utils';
import './style.scoped.less';

const Pagelet = props => {
  const {
    params: { apiHost, auth, headers = {} },
  } = props;

  const { functionId, functionName } = utils.getFunctionData(auth, '@didi/pagelet-promotion-overview') || {}; // 获取组件的id
  // 配置请求头信息
  const newHeaders = {
    ...headers,
    'bricks-function-id': functionId,
    'Content-Type': 'application/json',
    functionName: window.encodeURI(functionName),
  };
  // 初始化请求头
  newRequest.init(newHeaders);

  // 全局数据
  const [stateStore, stateDispatch] = useReducer(
    (stateStore, action) => {  // 返回及合并数据
      return {
        ...stateStore,
        ...action
      };
    }, {
    apolloConfig: { // apollo 配置信息
      pageLetInfo: {
        tipText: []
      }, // 组件的信息
      helpInfo: {}, // 帮助信息
      monthUrl: '', // 月份url
      cycleUrl: '', // 周期url
    },
  });

  // 页面数据
  const contextValue = useMemo(
    () => ({
      // 存储全局数据
      stateStore,
      stateDispatch,
      props
    }),
    [stateStore]
  );

  // 接口请求示例
  useEffect(async () => {
    const res = await getApolloConfig(apiHost); // 获取apollo配置
    stateDispatch({ apolloConfig: res });
  }, []);

  const prefixCls = 'pagelet-promotion-overview';

  const classes = classNames(prefixCls);

  return (
    <ConfigProvider prefixCls="ant5">
      <PublicStore.Provider value={contextValue}>
        <div className={classes}>
          <PageHeader />
          <PageContent />
          <PageBottom />
          <img className='leftImg' height='43' src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_SWUxIti6sPpvkAYADnjK'} />
          <img className='rightImg' width='57' src={'//img-ys011.didistatic.com/static/ddo_web_static/do1_Gdz63ZfVjKFcQPiBxYIK'} />
        </div>
      </PublicStore.Provider>
    </ConfigProvider>
  );
};

export default Pagelet;
