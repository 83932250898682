// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagelet-promotion-overview-content[data-v-681869e2] {
  margin-top: 29px;
  margin-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
}
.pagelet-promotion-overview-content p[data-v-681869e2] {
  padding-left: 12px;
  margin-bottom: 4px;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.84);
  position: relative;
  display: flex;
  align-items: center;
  font-family: '苹方-简';
  z-index: 1;
}
.pagelet-promotion-overview-content p[data-v-681869e2]:before {
  width: 4px;
  height: 4px;
  content: '';
  display: inline-block;
  position: absolute;
  top: 7px;
  left: 0;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.84);
}
.pagelet-promotion-overview-content .perfJumpBtn[data-v-681869e2] {
  font-family: PingFang SC;
  font-size: 14px;
  font-weight: 500;
  color: #FF6400;
  margin: 16px 0 12px;
  line-height: 32px;
  border-radius: 8px;
  background: #FFFFFF;
  border: 1px solid #FF6400;
  text-align: center;
  cursor: pointer;
}
.pagelet-promotion-overview-content .perfJumpBtn[data-v-681869e2]:hover {
  background: rgba(255, 100, 0, 0.04);
}
`, "",{"version":3,"sources":["webpack://./src/components/PageContent/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,mBAAmB;EACnB,OAAO;EACP,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,eAAe;EACf,iBAAiB;EACjB,0BAA0B;EAC1B,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,UAAU;AACZ;AACA;EACE,UAAU;EACV,WAAW;EACX,WAAW;EACX,qBAAqB;EACrB,kBAAkB;EAClB,QAAQ;EACR,OAAO;EACP,kBAAkB;EAClB,+BAA+B;AACjC;AACA;EACE,wBAAwB;EACxB,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,mCAAmC;AACrC","sourcesContent":[".pagelet-promotion-overview-content[data-v-681869e2] {\n  margin-top: 29px;\n  margin-bottom: 12px;\n  padding-left: 16px;\n  padding-right: 16px;\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n}\n.pagelet-promotion-overview-content p[data-v-681869e2] {\n  padding-left: 12px;\n  margin-bottom: 4px;\n  font-size: 12px;\n  line-height: 18px;\n  color: rgba(0, 0, 0, 0.84);\n  position: relative;\n  display: flex;\n  align-items: center;\n  font-family: '苹方-简';\n  z-index: 1;\n}\n.pagelet-promotion-overview-content p[data-v-681869e2]:before {\n  width: 4px;\n  height: 4px;\n  content: '';\n  display: inline-block;\n  position: absolute;\n  top: 7px;\n  left: 0;\n  border-radius: 50%;\n  background: rgba(0, 0, 0, 0.84);\n}\n.pagelet-promotion-overview-content .perfJumpBtn[data-v-681869e2] {\n  font-family: PingFang SC;\n  font-size: 14px;\n  font-weight: 500;\n  color: #FF6400;\n  margin: 16px 0 12px;\n  line-height: 32px;\n  border-radius: 8px;\n  background: #FFFFFF;\n  border: 1px solid #FF6400;\n  text-align: center;\n  cursor: pointer;\n}\n.pagelet-promotion-overview-content .perfJumpBtn[data-v-681869e2]:hover {\n  background: rgba(255, 100, 0, 0.04);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
