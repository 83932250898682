import React, { useContext } from 'react';
import { PublicStore } from '../../utils';
import classNames from 'classnames';
import './style.scoped.less';

const PageContent = () => {
  // 样式
  const prefixCls = 'pagelet-promotion-overview-content';
  const classes = classNames(prefixCls);

  // 状态
  const { stateStore } = useContext(PublicStore);
  const { apolloConfig } = stateStore;
  const { pageLetInfo } = apolloConfig;
  const { tipText } = pageLetInfo;

  return (
    <div className={classes}>
      {tipText?.map(item => (
        <p>
          <span>{item}</span>
        </p>
      ))}
    </div>
  );
};

export default PageContent;
