import { createContext } from 'react';

// 注册omega
const Omega = window?.Omega?.getTracker({
  appKey: 'omega9f21dcaf14',
  browserRouterEnable: true,
  autoPosition: false
});
// 公共store
export const PublicStore = createContext({});

// 获取apollo配置信息
export const getApolloConfig = apiHost => {
  const url = '/ep/as/conf?ns=ddo_tip&name=promotion_pagelets_config'; // url
  const host = apiHost.includes('promotion.didiglobal.com') ? '//as.xiaojukeji.com' : '//astest.intra.xiaojukeji.com'; // host
  const urlApollo = `${host}${url}`; // apollo配置地址
  return new Promise((resolve, reject) => {
    fetch(urlApollo)
      .then(data => data.json())
      .then(data => {
        if (data?.data?.length) {
          const apploObjConf = data.data[0]?.params;
          resolve({
            pageLetInfo: JSON.parse(apploObjConf?.['overviewSelf']), // 组件的信息 - 个人（overviewSelf）
            helpInfo: JSON.parse(apploObjConf?.helpInfo), // 帮助信息
            monthUrl: apploObjConf?.monthUrl, // 月份url
            cycleUrl: apploObjConf?.cycleUrl, // 周期url
          });
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

// 记录埋点
export const recordTrackEvent = props => {
  Omega?.trackEvent && Omega.trackEvent('ep_ddo_perf_pagelet_ck', '', {
    pagelet_name: '@didi/pagelet-promotion-overview',
    ...props
  });
};